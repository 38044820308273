<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div :class="`token-no token-no--${size}`" @click="handleToOwnerList">
    <img
      class="token-no-prefix"
      src="https://cdn.qiandaoapp.com/interior/images/eb2bf0e64a2fb7592e327cf57100c98a.png!mfit_w480_h480_png"
    />
    <span :class="`token-no-value token-no-value-length-${index.length}`">{{
      index
    }}</span>
    <img
      class="token-no-bg"
      src="https://cdn.qiandaoapp.com/interior/images/767c2866ffae733151d699bd8d2d4d7b.png!mfit_w480_h480_png"
      alt=""
    />
    <img
      class="token-no-line"
      src="https://cdn.qiandaoapp.com/interior/images/206093ee9521a45ca9c83605e59fab5c.png!mfit_w480_h480_png"
      alt=""
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  size: {
    type: String,
  },
  index: {
    type: String,
  },
});

const emits = defineEmits(["handleToOwnerList"]);

const handleToOwnerList = () => {
  emits("handleToOwnerList");
};
</script>

<style lang="scss" scoped>
.token-no {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 80rpx;
  width: 144rpx;
  height: 144rpx;
  transform: rotate(15deg);
  border-radius: 100%;
  background-color: rgba(white, 0.9);
  &-prefix {
    margin-top: 30rpx;
    width: 110rpx;
    height: 28rpx;
  }
  &-bg {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &-line {
    position: absolute;
    z-index: 2;
    left: 8rpx;
    bottom: 26rpx;
    width: 120rpx;
    height: 40rpx;
    transform: rotate(-15deg);
  }
  &-value {
    margin-top: 4rpx;
    color: rgba(114, 71, 220, 0.88);
    text-align: center;
    font-family: "Roboto";
    font-size: 64rpx;
    font-style: normal;
    font-weight: 900;
    line-height: 64rpx; /* 100% */
  }
  &-value-length-3 {
    font-size: 56rpx !important;
  }
  &-value-length-4 {
    font-size: 48rpx !important;
  }
  &-value-length-5 {
    font-size: 40rpx !important;
  }
}

.token-no--small {
  position: unset;
  width: 104rpx;
  height: 104rpx;
  .token-no-prefix {
    margin-top: 20rpx;
    width: 88rpx;
    height: 22rpx;
  }
  .token-no-line {
    width: 100rpx;
    height: 28rpx;
    left: 4rpx;
    bottom: 20rpx;
  }
  .token-no-value {
    font-size: 40rpx;
    line-height: 40rpx; /* 100% */
  }
  .token-no-value-length-4 {
    font-size: 32rpx !important;
  }
  .token-no-value-length-5 {
    font-size: 28rpx !important;
  }
}
</style>
