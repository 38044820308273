<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="section" v-if="logList.length">
    <div class="section-title">作品日志</div>
    <div class="plog">
      <content-wrp>
        <div
          class="plog-item info"
          v-for="(item, index) in logList"
          :key="index"
        >
          <!--          这里v-if 控制info-item未展开样式 -->
          <Transition name="fade">
            <div v-if="index === 0 || isExpand">
              <div class="info-item" v-if="item.type === 'TRANSFER'">
                <span class="boldtext namewrp">{{
                  item.newHolder?.displayName
                }}</span>
                <span class="graytext descgap">以</span>
                <span class="boldtext price">¥{{ item.price }}</span>
                <span class="graytext">买到该作品&nbsp;</span>
                <!--                #{{ item.tokenIndex }}-->
                <span class="timestamp">{{ item.timeString }}</span>
              </div>
              <div class="info-item" v-if="item.type === 'MINT'">
                <span class="boldtext namewrp">{{
                  item.oldHolder?.displayName
                }}</span>
                <!--                <span class="descgap graytext">在</span>-->
                <!--                <span class="descgap" />-->
                <!--                <Polygon />-->
                <!--                <span class="boldtext descgap">polygon</span>-->
                <!--                <span class="graytext descgap">上发布作品</span>-->

                <span class="graytext descgap">发布作品</span>
                <span class="timestamp">{{ item.timeString }}</span>
              </div>
            </div>
          </Transition>
        </div>

        <!--        <Transition name="fade">-->
        <!--          <div class="info-tips" v-if="collectionData && isExpand">-->
        <!--            <div class="info-tips-item" v-if="collectionData.contract.address">-->
        <!--              <span class="info-tips-item-label">合约地址</span>-->
        <!--              <span class="info-tips-item-value">{{-->
        <!--                collectionData.contract.address.slice(0, 6) +-->
        <!--                "..." +-->
        <!--                collectionData.contract.address.slice(-4)-->
        <!--              }}</span>-->
        <!--            </div>-->
        <!--            <div class="info-tips-item">-->
        <!--              <span class="info-tips-item-label">合约标准</span>-->
        <!--              <span class="info-tips-item-value">{{-->
        <!--                collectionData.contract.contractStandard-->
        <!--              }}</span>-->
        <!--            </div>-->
        <!--            <div class="info-tips-item">-->
        <!--              <span class="info-tips-item-label">作品ID</span>-->
        <!--              <span class="info-tips-item-value">{{ collectionData.id }}</span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </Transition>-->

        <div class="expand" @click="handleExpand" v-if="!isExpand">
          展开
          <ArrowRight classNames="down" />
        </div>
      </content-wrp>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineExpose } from "vue";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import { Button as VanButton } from "vant";
import { DuButton } from "@frontend/dumpling";
import ContentWrp from "@/components/ContentWrp/index.vue";
import Polygon from "@/components/Polygon/index.vue";
import ArrowRight from "@/components/ArrowRight/index.vue";
import NftApi from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import { getQuery } from "@/utils/url";

defineProps({
  collectionData: {
    type: Object,
    required: true,
  },
  tokenId: {
    type: String,
    required: true,
  },
});

const logList = ref([]);
const isExpand = ref(false);

const handleExpand = () => {
  isExpand.value = true;
};

const initLogs = async () => {
  const { tokenId } = getQuery();
  const res = await NftApi.getTokenLogs({ tokenId, limit: 10, offset: 0 });

  if (res.code !== REQUEST_OK) return;

  const tempList = res.data?.list || [];
  logList.value = tempList.map((item) => {
    item.timeString = dayjs(+item.time).format("YYYY-MM-DD");
    return item;
  });
};

initLogs();

defineExpose({
  initLogs,
});
</script>

<style lang="scss" scoped>
.plog {
  margin-top: 32rpx;
  &-item {
    margin-top: 32rpx;
  }
  &-item:first-child {
    margin-top: 0;
  }
  .info {
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 16rpx;
      font-size: 24rpx;
      line-height: 36rpx;
      color: #000000;
    }
    &-item:first-child {
      margin-top: 0;
    }
    .boldtext {
      font-weight: bold;
    }
    .graytext {
      color: rgba(0, 0, 0, 0.5);
    }
    .boldtext-suffix,
    .descgap {
      margin-left: 8rpx;
    }
    .price {
      margin: 0 8rpx;
    }
    .namewrp {
      max-width: 150rpx;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .timestamp {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &-tips {
      font-weight: 400;
      font-size: 20rpx;
      line-height: 22rpx;
      color: rgba(0, 0, 0, 0.5);
      &-item {
        margin-top: 16rpx;
        &-label {
        }
        &-value {
          margin-left: 8rpx;
          color: #000000;
        }
      }
    }
  }
  .expand {
    margin: 16rpx auto 0 auto;
    font-weight: 500;
    font-size: 24rpx;
    line-height: 36rpx;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
  }
  .down {
    transform: rotate(90deg);
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<!--v-model	min max step-->
