<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <!--  <div :class="['page', actionConfig.isShowActionBar && 'page&#45;&#45;withauction']">-->
  <div :class="['page', 'page--withauction']">
    <van-sticky>
      <van-nav-bar @click-left="onClickLeft">
        <template #left>
          <van-image
            fit="cover"
            class="nav-back"
            src="https://cdn.qiandaoapp.com/interior/images/07e5d5fa0e6ae47f3be4d9fe31a5c616.png"
            alt=""
          />
        </template>
        <template #title>
          <div class="ownerwrp" v-if="detailData.owner">
            <div class="owner">
              <img
                class="owner-avatar"
                :src="detailData.owner.avatarUrl + '!mfit_w480_h480_jpg'"
                alt=""
              />
              <div class="owner-content">
                <div class="owner-content-top">
                  {{ detailData.owner.displayName }}
                </div>
                <div class="owner-content-bottom" v-if="detailData.ownTime">
                  <span>{{ formateTime(detailData.ownTime) }}</span
                  >&nbsp; <span>拥有该作品</span>&nbsp;
                  <span>#{{ detailData.tokenIndex }}</span>
                </div>
              </div>
            </div>
            <token-number
              @handleToOwnerList="handleToOwnerList"
              :index="detailData.tokenIndex.toString()"
              v-if="
                !isElementVisible &&
                detailData.tokenIndex &&
                detailData.type === 'SFT'
              "
              size="small"
            />
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <!--    <browser-cover />-->
    <download-banner @onInvokeApp="onInvokeApp" />
    <suspect-notice :suspectedCount="+detailData.suspectedCount" />

    <div v-if="detailData.name && loaded" class="token-content">
      <div class="main-img">
        <img
          v-if="detailData?.watermaskImages?.[0]?.url"
          class="main-img--value"
          oncontextmenu="return false;"
          :src="detailData?.watermaskImages?.[0]?.url + '!mfit_w750_h750_jpg'"
          alt=""
        />
        <token-number
          @handleToOwnerList="handleToOwnerList"
          :index="detailData.tokenIndex.toString()"
          v-if="
            isElementVisible &&
            detailData.tokenIndex &&
            detailData.type === 'SFT'
          "
        />
      </div>
      <div ref="bigTokenNoRef" />
      <div class="head">
        <div class="head-title">
          <div
            class="head-title-cover"
            v-if="detailData?.covers?.[0]?.url"
            @click="handleShowCovers"
          >
            <van-image
              fit="cover"
              class="head-title-cover-img"
              :src="detailData.covers[0].url + '!mfit_w750_h750_jpg'"
              alt=""
            />
            <img
              class="head-title-cover-icon"
              src="https://cdn.qiandaoapp.com/ued/9c0dc70eaaccb3ee9c9a940bc5184436.png"
              alt=""
            />
          </div>
          <div class="head-title--name otext2">
            {{ detailData.name }}
          </div>
          <div class="head-title--likecount" v-if="+detailData.likeCount">
            <img
              src="https://cdn.qiandaoapp.com/ued/06155ec80ce5ff0158969e2c41c98ae0.png"
              alt=""
            />
            <div class="head-title--likecount--value">
              {{ detailData.likeCount }}
            </div>
          </div>
        </div>
        <!--      批发类型    -->
        <div class="tags" v-if="detailData.type === 'SFT'">
          <!--      <div class="tags" v-if="true">-->
          <div class="tags-batch">批发类作品</div>
          <!--        <div class="tags-clothes">服设</div>-->
        </div>

        <!--      TODO 超过4行隐藏  https://blog.csdn.net/Dilomen/article/details/102756534 -->
        <div class="content" v-if="detailData.description">
          <!--      <div class="section-title">作品描述</div>-->
          <div class="content-value">{{ detailData.description }}</div>
        </div>

        <div>
          <oc-overview
            :detailData="detailData"
            @onInvokeApp="onInvokeApp"
            :belongedCharacters="detailData.belongedCharacters"
            :owner="detailData.owner"
          />
        </div>

        <token-owner-list
          ref="tokenOwnerListRef"
          @handleToOwnerList="handleToOwnerList"
          v-if="detailData.originTokenId"
          :detailData="detailData"
        />
      </div>

      <!--      &lt;!&ndash;  持有者  &ndash;&gt;-->
      <!--      <author-info :detailData="detailData" />-->

      <!--  当前登录人是持有者  -->
      <owner-actions
        v-if="detailData.isOwner && detailData.artType !== 'SPACE_FOR_ARTWORK'"
        :originImages="detailData.originImages"
      />

      <!--  作品日志  -->
      <paintings-log
        ref="paintingsLogRef"
        :collectionData="detailData.collection"
      />

      <!--      &lt;!&ndash;  购前须知  &ndash;&gt;-->
      <!--      <pre-purchase-tips-->
      <!--        v-if="detailData.tradeInfo?.status === 'SELLING'"-->
      <!--        :currentStage="detailData?.purchaseInstructionType"-->
      <!--        :creatorId="detailData?.publisher?.userId"-->
      <!--        :watermaskImage="detailData?.watermaskImages?.[0]?.url"-->
      <!--      />-->

      <!--  流程提示  -->
      <!--    <process-tips :detailData="detailData" />-->

      <!--      &lt;!&ndash;  拍卖出价表  &ndash;&gt;-->
      <!--      <bid-list-->
      <!--        ref="bidList"-->
      <!--        v-if="detailData?.tradeInfo?.auctionInfo?.status === 'BIDDING'"-->
      <!--        :bidCount="detailData?.tradeInfo?.auctionInfo?.bidCount"-->
      <!--        :auctionId="detailData?.tradeInfo?.tradeOrderId"-->
      <!--      />-->

      <!--  评论列表  -->
      <comment-list />
      <!--  action btn  -->
      <!--    <div class="auction" v-if="detailData.tradeInfo?.type === 'AUCTION'">-->

      <template v-if="canShowActionBar">
        <!--    批量类型 || 一口价 的 -->
        <template v-if="isBuyoutType">
          <action-product @onInvokeApp="onInvokeApp" />
        </template>
        <template v-else>
          <!--  拍卖:出价&支付按钮  -->
          <action-bid @onInvokeApp="onInvokeApp" />
        </template>
      </template>

      <!--      <div v-else>-->
      <!--      </div>-->
    </div>
    <div class="token-empty" v-if="loaded && !detailData.name">
      <div class="token-empty-txt">找不到作品</div>
      <main-button
        @click="goToProfilePage"
        text="访问该作者主页"
        size="large"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  provide,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import dayjs from "dayjs";
import {
  Popup as VanPopup,
  Image as VanImage,
  showFailToast,
  showLoadingToast,
  showImagePreview,
  showSuccessToast,
  closeToast,
  NavBar as VanNavBar,
  Sticky as VanSticky,
} from "vant";
import NftApi from "@/apis";
import DownloadBanner from "@/components/DownloadBanner";
import MainButton from "@/components/MainButton";
import ActionBid from "./components/ActionBid.vue";
import ActionProduct from "./components/ActionProduct.vue";
import AuthorInfo from "./components/AuthorInfo.vue";
import OwnerActions from "./components/OwnerActions.vue";
import PaintingsLog from "./components/PaintingsLog.vue";
import PrePurchaseTips from "@/components/PrePurchaseTips";
// import ProcessTips from "./components/ProcessTips.vue";
import CommentList from "@/components/CommentList/index.vue";
import BidList from "./components/BidList.vue";
import OcOverview from "./components/OcOverview.vue";
import TokenOwnerList from "./components/TokenOwnerList.vue";
import SuspectNotice from "./components/SuspectNotice.vue";
import TokenNumber from "./components/TokenNumber.vue";
import { getQuery } from "@/utils/url";
import { formatTimestamp, getCountdownTime } from "@/utils/time";
import { invokeAppByYYB } from "@/utils/mob";
import { useUsersStore } from "@/store/user";
import { RESERVERPRICE_STAGE, AUCTION_BTN_STAGE } from "@/constant/auction";
import { ART_TYPE, TRADE_STATUS } from "@/constant/contract";
import { useElementVisibility } from "@/hooks/useElementVisibility";
import { emitter } from "@/utils/emitter";
import ProductApi from "@/apis/product";

const AUCTION_STAGE = {
  BIDDING: "BIDDING",
  WAIT_SELLER_CONFIRM: "WAIT_SELLER_CONFIRM",
  SETTLING: "SETTLING",
};

// WAIT_BUYER_CONFIRM: "WAIT_BUYER_CONFIRM",

const userStore = useUsersStore();
const router = useRouter();
const route = useRoute();

const { userInfo, getIsLogin } = storeToRefs(userStore);

// const { isShowPrePurchasePopup } = useBtnAction();

const loaded = ref(false);
const bigTokenNoRef = ref();
const { isElementVisible } = useElementVisibility(bigTokenNoRef);
let detailData = ref({});
let tradeInfoData = ref({});
let auctionInfo = ref({});
let actionConfig = ref({});
let contractPerformingConfig = ref({}); // 履约信息
let bidList = ref();
let buyoutDetailData = ref({});
let auctionDetailData = ref({});
let paintingsLogRef = ref();
let tokenOwnerListRef = ref();
const prePurchaseConfig = computed(() => ({
  currentStage: detailData.value?.purchaseInstructionType,
  creatorId: detailData.value?.publisher?.userId,
  watermaskImage: detailData?.value?.watermaskImages?.[0]?.url,
}));

const inst = getCurrentInstance();

const buyoutDetailDataRef = computed(() => {
  return buyoutDetailData.value;
});

const auctionDetailDataRef = computed(() => {
  return auctionDetailData.value;
});

const isBuyoutType = computed(() => {
  if (detailData.value?.tradeInfo?.type === "SALE") {
    return true;
  }
  return false;
});

const isOwner = computed(() => {
  return detailData.value?.isOwner;
});

const canShowActionBar = computed(() => {
  const { tradeInfo } = detailData.value;
  return (
    (tradeInfo.status === "SELLING" || tradeInfo.status === "FUTURE_SELLING") &&
    tradeInfo.tradeOrderId
  ); // 拍卖一口价是否有挂单都用这个status==='SELLING' 佳磊去cover 具体挂单状态
});

const iAmMaxBidUser = computed(() => {
  const maxBidUserId = tradeInfoData.value?.auction?.currentBidUserId;
  return maxBidUserId && maxBidUserId === userInfo.value.userId;
});

provide("buyoutDetailData", buyoutDetailDataRef);
provide("auctionDetailData", auctionDetailDataRef);
provide("prePurchaseConfig", prePurchaseConfig);
provide("isOwner", isOwner);

const onClickLeft = () => {
  router.back();
};

const goToProfilePage = () => {
  const { publisherUserId, tokenId } = getQuery();
  if (!publisherUserId) {
    showFailToast("作品已删除");
    invokeAppByYYB({
      targetPath: "token-detail",
      params: {
        tokenId,
      },
    });
    return;
  }
  invokeAppByYYB({
    targetPath: "profile",
    params: { userId: publisherUserId },
  });
};

const onInvokeApp = () => {
  const { tokenId } = getQuery();
  invokeAppByYYB({
    targetPath: "token-detail",
    params: {
      tokenId,
    },
  });
};

const handleShowCovers = () => {
  const tempImgs = detailData.value.covers.map(
    (item) => item.url + "!mfit_w750_h750_jpg"
  );
  showImagePreview(tempImgs);
};

const onRefreshTokenDetail = () => {
  getTokenDetail();
  inst?.refs?.bidList?.initBidList();
  inst?.refs?.paintingsLogRef?.initLogs();
  inst?.refs?.tokenOwnerListRef?.initAllData();
};

const handleToOwnerList = () => {
  router.push(`/token-owner-list?tokenId=${detailData.value.originTokenId}`);
};

const formateTime = (time) => {
  return `${dayjs(+time).format("YYYY-MM-DD")}`;
};

const getTokenDetail = async () => {
  const { tokenId } = getQuery();
  const { data } = await NftApi.getTokenDetail({ tokenId });
  loaded.value = true;
  detailData.value = data?.token || {};

  if (canShowActionBar.value) {
    const { tradeInfo } = detailData.value;
    if (isBuyoutType.value) {
      // if (false) {
      const buyoutRes = await ProductApi.getBuyoutProductDetail({
        id: tradeInfo.tradeOrderId,
      });

      console.log("buyoutRes===", buyoutRes);
      buyoutDetailData.value = buyoutRes.data || {};
      buyoutDetailData.value.product.isFutureSelling =
        +buyoutDetailData.value.product.startTime > new Date();
      buyoutDetailData.value.product.startTimeString =
        formatTimestamp(+buyoutDetailData.value.product.startTime) + " 开售";
    } else {
      const auctionRes = await ProductApi.getAuctionProductDetail({
        id: tradeInfo.tradeOrderId,
      });
      auctionDetailData.value = auctionRes.data || {};
      auctionDetailData.value.isFutureSelling =
        auctionDetailData.value.startTime > +new Date();
      auctionDetailData.value.startTimeString =
        formatTimestamp(+auctionDetailData.value?.startTime) + " 开拍";
      auctionDetailData.value.finishTimeString =
        formatTimestamp(+auctionDetailData.value?.finishTime) + " 截拍";

      console.log("auctionRes===", auctionRes);
    }
  }

  console.log("detailData===", detailData);
};

const processQuery = async () => {
  const { productType } = getQuery();
  if (productType === "SFT") {
    // 购买成功了, 回到了批发作品, 那么查询自己owner的是哪个
    showSuccessToast("您已拥有同款页面");
    const { data } = await NftApi.getOwnerList({
      tokenId: detailData.value.originTokenId,
      limit: 100,
      offset: 0,
      onlyMine: true,
    });

    console.log("data?.list?.[0]===", data?.list);

    const tempList = data?.list?.reverse();
    const tempId = tempList?.[0]?.id;

    setTimeout(() => {
      router.replace(`/token-detail?tokenId=${tempId}`);
    }, 1500);
  }
};

getTokenDetail();

watch(
  () => route.query,
  (newParams, oldParams) => {
    if (newParams.tokenId !== oldParams.tokenId && newParams.tokenId) {
      console.log("tokenId has changed to:", newParams.tokenId);
      onRefreshTokenDetail();
    }
  }
);

watch(
  () => detailData.value,
  (newParams, oldParams) => {
    if (newParams.originTokenId) {
      processQuery();
    }
  },
  {
    deep: true,
  }
);

onMounted(() => {
  emitter.on("INIT_TOKEN_DETAIL", getTokenDetail);
});
</script>

<style lang="scss">
.page {
  font-size: 24rpx;
  position: relative;
  min-height: 100vh;
  padding-bottom: 36rpx;
  background-color: #f8f8f8;
}
.page--withauction {
  padding-bottom: 260rpx;
  //padding-bottom: 1220rpx;
}
.main-img {
  position: relative;
  padding: 60rpx 60rpx 0 60rpx;
  width: 100%;
  overflow: hidden;
  &--value {
    aspect-ratio: 1;
    object-fit: contain;
    width: 630rpx;
    //height: 630rpx;
    height: auto;
    border-radius: 48rpx;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.tags {
  display: flex;
  align-items: center;
  margin: 22rpx 0 16rpx 0;
  &-batch {
    height: 44rpx;
    padding: 0 8rpx;
    background: #7247dc;
    border-radius: 4rpx;
    line-height: 44rpx;
    font-weight: bold;
    font-size: 24rpx;
    color: #ffffff;
  }
  &-clothes {
    margin-left: 8rpx;
    padding: 0 8rpx;
    height: 44rpx;
    box-sizing: border-box;
    border: 2rpx solid #7247dc;
    border-radius: 4rpx;
    line-height: 44rpx;
    font-weight: bold;
    color: #7247dc;
  }
}
.head {
  padding: 0 30rpx;
  &-title {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    margin-top: 48rpx;
    text-align: left;
    &-cover {
      position: relative;
      margin-right: 16rpx;
      width: 144rpx;
      height: 144rpx;
      box-shadow: 8rpx 8rpx 16rpx rgba(0, 0, 0, 0.06);
      border-radius: 24rpx;
      overflow: hidden;
      //background-color: red;
      &-img {
        width: 144rpx;
        height: 144rpx;
      }
      &-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40rpx;
        height: 40rpx;
      }
    }
    &--name {
      width: 422rpx;
      font-weight: 800;
      font-size: 64rpx;
      line-height: 80rpx;
      color: #000000;
    }
    &--likecount {
      margin-left: auto;
      flex: 0 0 92rpx;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 92rpx;
      height: 92rpx;
      border-radius: 100%;
      box-shadow: 0px 8rpx 40rpx rgba(0, 0, 0, 0.06);
      background-color: #fff;
      img {
        width: 40rpx;
        height: 40rpx;
      }
      &--value {
        margin-top: 4rpx;
        font-weight: 900;
        font-size: 20rpx;
        line-height: 22rpx;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &-id {
    margin-top: 18rpx;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 24rpx;
    //line-height: 36rpx;
    color: rgba(0, 0, 0, 0.5);
    &--icon {
      margin: 0 8rpx;
      width: 36rpx;
      height: 40rpx;
    }
    &--hash {
      display: flex;
      align-items: center;
      word-break: break-all;
      max-width: 600rpx;
    }
  }
  &-status {
    margin-top: 50rpx;
    display: flex;
    flex-direction: row;
    &--item {
      flex: 0 0 33.33%;
      text-align: left;
    }
    .item {
      &-label {
        font-size: 20rpx;
        line-height: 22rpx;
        color: #2a2c24;
      }
      &-value {
        margin-top: 4rpx;
        font-weight: 800;
        font-size: 32rpx;
        line-height: 48rpx;
        letter-spacing: 0.01em;
        color: #000000;
      }
    }
  }
}
.section {
  padding: 0 30rpx;
  &-title {
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 800;
    margin-top: 48rpx;
    font-size: 48rpx;
    line-height: 64rpx;
    color: #000000;
    &-subtitle {
      margin-left: 16rpx;
      font-weight: 400;
      font-size: 28rpx;
      line-height: 44rpx;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
.content {
  margin-top: 28rpx;
  &-value {
    font-size: 28rpx;
    line-height: 44rpx;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
  }
}

//.auction {
//  position: relative;
//  display: flex;
//  align-items: center;
//  justify-content: space-between;
//  position: fixed;
//  bottom: 0;
//  left: 0;
//  padding: 16rpx 30rpx 68rpx 30rpx;
//  width: 100%;
//  background-color: white;
//  &-left {
//    &--top {
//      display: flex;
//      align-items: center;
//      .price {
//        font-weight: 900;
//        font-size: 40rpx;
//        line-height: 52rpx;
//        color: #000000;
//      }
//      .count {
//        margin-left: 8rpx;
//        font-size: 24rpx;
//        line-height: 36rpx;
//        color: rgba(0, 0, 0, 0.5);
//      }
//    }
//  }
//  &-right {
//  }
//}

.nav-back {
  width: 66rpx;
  height: 66rpx;
  border-radius: 100%;
  box-shadow: 0 8rpx 40rpx rgba(0, 0, 0, 0.06);
}

.ownerwrp {
  display: flex;
  align-items: center;
  height: 88rpx;
  overflow: hidden;
  .owner {
    margin-right: 8rpx;
    display: flex;
    &-avatar {
      width: 64rpx;
      height: 64rpx;
      flex-shrink: 0;
      object-fit: cover;
      border-radius: 100%;
      border: 2rpx solid rgba(0, 0, 0, 0.06);
    }
    &-content {
      text-align: left;
      margin-left: 16rpx;
      &-top {
        color: #000;
        font-size: 28rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 44rpx; /* 150% */
      }
      &-bottom {
        color: rgba(0, 0, 0, 0.5);
        font-size: 20rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 22rpx; /* 150% */
      }
    }
  }
}

.token-content {
}
.token-empty {
  margin: 30vh auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-txt {
    margin-bottom: 24rpx;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 44rpx; /* 157.143% */
  }
}

.van-icon__image {
  width: 32rpx;
  height: 32rpx;
}
.page {
  --van-nav-bar-height: 88rpx;
  .van-nav-bar__title {
    max-width: 90%;
  }
}
//.van-image__img,
//.van-image-preview__image,
//img,
//.head-title-cover-img {
//  -webkit-touch-callout: none; /* 禁止弹出菜单 */
//  -webkit-user-select: none; /* 禁止选中文本 */
//  -khtml-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}
</style>
